import Post from "../postModel";

export default new Post(
  // title
  "Keep your head up",
  // subtitle
  "Just a personal story of navigating tough times",
  // publishDate
  new Date("2021-01-20"),
  // titleImageUrl
  "https://pb-data-blogposts.s3.eu-central-1.amazonaws.com/keep-your-head-up/brain.jpg",
  // titleImageDescription
  "Meningoencephalitis isn't fun",
  // tags
  ["Non-Tech"],
  // content
  `I haven't been writing much lately, nor have I been very productive in general over the last two months. That is because I've had some pretty challenging weeks due to being seriously sick. This is absolutely nothing technical, just a personal story of mine, and I'm writing this mostly for myself to reflect on the past few weeks. For those who are going or have gone through a similar experience, it may be an interesting read though, I don't know.

### Suspiciously OK

The year 2020 has been a huge challenge for the whole world. Due to the Covid-19 pandemic, many people lost their health, life, or loved ones. The economy of most nations took a strong hit and many people lost their jobs or had to watch their businesses go bankrupt. Lock-downs forced people to pause most of their beloved social activities and resulted in isolation and loneliness. Until the last quarter of 2020, that wasn't my personal year though. Just a month before the virus hit Germany, I had started a great new job, moved back to my hometown after more than 20 years, and met awesome new people. As a Data Scientist, I had no problem working from home (I got even more productive) and I was used to talking to many of my close friends only via video-call anyways. I felt that both my professional and personal lives were going well...maybe a bit too well, considering that the world around me was in crisis-mode.

### Getting sick

In the end, it wasn't Covid that got me, but most likely a tick bite. The exact pathogen couldn't be found, but symptoms and presence of corresponding anti-bodies point towards a tick-bourne encephalitis; a brain inflammation caused by a virus transmitted via ticks, native to where I went on vacation just a few weeks earlier. One usual morning, I just finished my daily meditation and went back to bed to read a bit, I suddenly felt a headache. At first, I didn't think much about it but the pain intensified very quickly. Half an hour later I was in strong pain, one of my arms feeling numb, and my sense of balance wasn't working properly anymore. I tried to get through the bathroom door but ran straight into the door frame. My girlfriend, being worried about me asked what was going on, but I couldn't answer properly anymore. The words just wouldn't come out. I tried to send an email or text to my boss to tell him that I'm wasn't coming to work today, but I couldn't type or write anymore either.

This was the start of one of the most horrible days I've ever experienced. I also experienced that the German healthcare system, which loves to praise itself for how incredibly amazing it allegedly is, really doesn't give a shit about you if you're a patient with _"only"_ compulsory governmental health insurance. Despite my symptoms matching a stroke, they wouldn't send an ambulance to get me so my girlfriend and flatmate had to carry me into a public bus and to the nearest hospital. I don't remember all the details of that day anymore; it felt like a long stream of pain and fear. The headaches were getting worse by the minute and I couldn't talk, couldn't communicate with any of the doctors or nurses that were pushing me through all sorts of machines and sticking pointy things into my body. I clearly remember a moment in which I was left alone on an uncomfortable stretcher in an ice cold room with lots of needles and cables sticking out of me, my head almost exploding, and I was crying because I had no idea where this was going and I was afraid. Anxiously wondering if I'll survive this day at all, if I'll ever see my loved ones again or could talk to them with the voice I had lost, if I will be mentally disabled for the rest of my life. And the only thing I could do about it was to scream at the top of my lungs.

It went on like this until in the evening someone rammed a huge needle into my spine to take some cerebrospinal fluid for diagnostic testing and then later told me that I had a brain inflammation. Great, what does that mean? Apparently it meant that I had to stay at the hospital for a few weeks for a antibiosis therapy until they had found and eliminated the pathogen that was causing the inflammation. My ability to speak and to move my body normally were returning quite quickly after the first attack, but the severe headaches stayed. Unfortunately, they also didn't find the type of bacteria or virus that was causing the inflammation. I cannot judge if that is common, but it didn't feel like the doctors were putting in a lot of effort (or even interest). No one there was really caring much about their patients; delivering meals and medicine three times a day and that was it. Due to the Covid pandemic, no visitors were allowed either, so it were some pretty lonely weeks under constant pain.

### Wonderful people

I don't know in which mental condition I would be now, if it wasn't for a lot of great people around me. Despite not being allowed to enter the hospital, my girlfriend and flatmates dropped of everything I needed (first and foremost my laptop :D) in front of its doors and later on met me outside with enough distance in between. My friends, many of them from the other end of the world, checked in on me and called me to tell me that everything is going to be alright. My grandma called every day to tell me that I have to keep fighting the illness. Even my manager and some coworkers called frequently to ask how I was doing and wish me to get well soon. I was worried about how they would perceive me being absent from work for almost four weeks, but everyone assured me that it was fine and I should focus on healing and not mind work at all. After leaving the hospital, still with headaches and not knowing which particular pathogen caused the inflammation, it wasn't easy to make the first small steps back into normal life. But again, everyone at home and at work was very understanding about me not yet being back to 100% performance or having to spontaneously go to emergency room again. Slowly but steadily things were getting better, I started exercising and meditating again, the headaches were getting less severe, and I gained back my strength.

### Acceptance

It was a terrible time for me but it could have been much worse if I hadn't had my loved ones and colleagues cheering me up. I also had to learn to cheer myself up, accept my situation, and just make myself believe (reasonable or not) that things were going to get better eventually. Of course, my head was full of dark questions that I had to keep answering to myself. Why did it hit me? What did I do? Nothing, randomness, and accident, it just happened and now I have to accept it. Will I ever be like I was before? Will I ever fully recover from the effects of this brain disease? I don't know, I hope so. At least I was sure that worrying about it isn't going to accelerate my recovery, it would just make me feel worse. I didn't have any active influence over the course of this disease; the only thing I could do is to bare it, accept my fate, and hope for better future days to come. And better days came. And I was dragged there by the people who care for me and my own stubborn, trying-to-be-equanimous mind. Now, two months after the whole thing stated, I hopefully got the very last spinal tap of my life and the result doesn't show a significant inflammation of my brain anymore. Almost healthy again, just a little bit of headaches left, finally.

### What I've learned

I guess I just felt like writing this down for myself. But also to tell anyone who's going through though times (and that's probably a lot of people with the pandemic still raging): Keep your head up! You mustn't lose hope. Bad things may happen just like that and, often, there's nothing we can do to change that. The Covid pandemic is the best example for that. But we can try to make the best out of it and hope for better times. Keep the people that care for you and that you care for as close as social distancing allows it. Try to be not alone. And try to accept your fate; instead of ruminating how terrible things are all the time, try to think about what you can do to make it better and then stop caring about the rest. Look forward and believe that, eventually, those better days will come. This may all sound a bit cheesy, but it is what helped me getting through almost two months of pain and hardship without becoming depressed or insane. Thanks to all the folks who helped me getting better! And thanks for reading, stay safe, healthy, happy :)
`
);
